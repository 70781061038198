import { Grid, makeStyles } from "@material-ui/core";
import { Location as LocationCity } from "../icon";

const useStyles = makeStyles((theme) => ({
  location: {
    marginTop: "15px",
    paddingTop: "15px",
    borderTop: "1px solid #ddd",
    color: "#2E323C",
    "& svg": {
      marginRight: "4px",
    },
  },
  icon: {
    display: "inline-block",
    marginLeft: "-5px",
    marginRight: "10px",
  },
  "@media (max-width: 599px)": {
    location: {
      borderTop: "none",
      fontSize: "12px",
      padding: "0",
      margin: "0",
      "& svg": {
        width: "15px",
        height: "14px",
      },
    },
    icon: {
      width: "18px",
      height: "17px",
    },
  },
}));

const Location = ({ city }) => {
  const classes = useStyles();

  return (
    <div className={classes.location}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={{ display: "flex" }}>
          <LocationCity className={classes.icon} />
        </Grid>
        <Grid item>{city}</Grid>
      </Grid>
    </div>
  );
};

export default Location;
