import * as React from "react";

const BrokenHeart = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      fill="#F7F7F8"
      className={className}
    >
      <path d="M8.01683 14.6167C6.52794 13.1167 5.34183 11.9056 4.4585 10.9833C3.57516 10.0611 2.90294 9.29445 2.44183 8.68333C1.98072 8.07222 1.68072 7.55 1.54183 7.11667C1.40294 6.68333 1.3335 6.2 1.3335 5.66667C1.3335 4.64444 1.68905 3.77778 2.40016 3.06667C3.11127 2.35556 3.97794 2 5.00016 2C5.50016 2 5.9835 2.09167 6.45016 2.275C6.91683 2.45833 7.3335 2.72222 7.70016 3.06667L6.66683 6.66667H8.66683L8.10016 12.25L10.0002 6H8.00016L9.1835 2.46667C9.46127 2.31111 9.75294 2.19444 10.0585 2.11667C10.3641 2.03889 10.6779 2 11.0002 2C12.0224 2 12.8891 2.35556 13.6002 3.06667C14.3113 3.77778 14.6668 4.64444 14.6668 5.66667C14.6668 6.2 14.5946 6.68889 14.4502 7.13333C14.3057 7.57778 14.0002 8.10833 13.5335 8.725C13.0668 9.34167 12.3946 10.1083 11.5168 11.025C10.6391 11.9417 9.47238 13.1389 8.01683 14.6167ZM6.8335 11.5167L7.1835 8H4.90016L6.15016 3.61667C5.97238 3.52778 5.78627 3.45833 5.59183 3.40833C5.39739 3.35833 5.20016 3.33333 5.00016 3.33333C4.35572 3.33333 3.80572 3.56111 3.35016 4.01667C2.89461 4.47222 2.66683 5.02222 2.66683 5.66667C2.66683 6.01111 2.73072 6.35556 2.8585 6.7C2.98627 7.04445 3.2085 7.43611 3.52516 7.875C3.84183 8.31389 4.26961 8.825 4.8085 9.40833C5.34738 9.99167 6.02238 10.6944 6.8335 11.5167ZM9.96683 10.7167C11.2002 9.46111 12.0696 8.46111 12.5752 7.71667C13.0807 6.97222 13.3335 6.28889 13.3335 5.66667C13.3335 5.02222 13.1057 4.47222 12.6502 4.01667C12.1946 3.56111 11.6446 3.33333 11.0002 3.33333C10.8779 3.33333 10.7557 3.34167 10.6335 3.35833C10.5113 3.375 10.3891 3.40556 10.2668 3.45L9.86683 4.66667H11.8002L9.96683 10.7167Z" />
    </svg>
  );
};

export default BrokenHeart;
