export const setCookie = (name, value, options, res) => {
  const cookieString = stringifyCookie(name, value, options);

  if (res) {
    res.setHeader("Set-Cookie", cookieString);
    return;
  }

  if (typeof window !== "undefined") {
    document.cookie = cookieString;
  }
};

export const getCookies = (req) => {
  if (req) {
    return req.cookies || {};
  }

  if (typeof window !== "undefined") {
    return parseCookie(document.cookie);
  }

  return {};
};

const stringifyCookie = (name, value, options) => {
  const cookie = `${name}=${value};`;

  if (!options) {
    return cookie;
  }

  const expires = options.expires
    ? ` expires=${options.expires.toUTCString()};`
    : "";
  const path = ` path=${options.path || "/"};`;
  const httpOnly = options.httpOnly ? " httponly;" : "";
  const secure = options.secure ? " secure;" : "";
  const sameSite = ` samesite=${options.sameSite || "lax"} ;`;

  return `${cookie}${expires}${path}${httpOnly}${sameSite}${secure}`;
};

const parseCookie = (cookieString) => {
  return cookieString.split("; ").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=");
    return { ...acc, [key]: value };
  }, {});
};
