import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { IconButton, makeStyles } from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { useAuth } from "hooks/useAuth";
import { useBookmark } from "hooks/useBookmark";
import BrokenHeart from "./icon/BrokenHeart";

const DLoginModal = dynamic(() => import("components/Login/LoginModal"), {
  ssr: false,
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E9ECEE60 !important",
    padding: theme.spacing(0.5),
  },
}));

const RESALE_GROUP = "Revenda";

const BookmarkTag = ({
  sku,
  group,
  className,
  hideOnDisabled,
  size = "small",
}) => {
  const classes = useStyles();
  const bookmark = useBookmark();
  const { isAuthenticated, session } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [bookmarked, setBookmarked] = useState(true);

  useEffect(() => {
    setBookmarked(bookmark.data.includes(sku));
  }, [bookmark.data, sku]);

  const handleClick = () => {
    if (!isAuthenticated) {
      return setIsOpen(true);
    }

    setBookmarked((state) => !state);

    if (bookmarked) {
      return bookmark.remove(sku);
    }

    bookmark.add(sku);
  };

  const isDisabledToUser =
    isAuthenticated &&
    group &&
    group !== RESALE_GROUP &&
    !session?.groups?.some((userGroup) => userGroup.label === group);

  if (isDisabledToUser) {
    if (hideOnDisabled) {
      return null;
    }

    return (
      <IconButton
        disabled
        size="small"
        className={`${classes.root} ${className}`}
      >
        <BrokenHeart />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        disabled={bookmark.isLoading}
        onClick={handleClick}
        size="small"
        focusRipple
        className={`${classes.root} ${className}`}
      >
        {bookmarked ? (
          <Favorite color="primary" fontSize={size} />
        ) : (
          <FavoriteBorder color="secondary" fontSize={size} />
        )}
      </IconButton>
      {isOpen ? <DLoginModal onClose={() => setIsOpen(false)} /> : null}
    </>
  );
};

export default BookmarkTag;
