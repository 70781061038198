import { makeStyles } from "@material-ui/core";
import BookmarkTag from "components/BookmarkTag";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(1),
      right: "unset",
    },
  },
}));

const CardBookmarkTag = ({ sku, group }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BookmarkTag sku={sku} group={group} />
    </div>
  );
};

export default CardBookmarkTag;
