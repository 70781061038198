import React from "react";
import Carousel from "components/Carousel";
import SingleCardImage from "./SingleCardImage";

const CardImageCarousel = ({ href, sku, imageList, hasIpvaPaid }) => {
  return (
    <Carousel autoPlay={false} animation={"fade"} indicators>
      {imageList.slice(0, 4).map((image, index) => (
        <SingleCardImage
          href={href}
          key={image.title}
          loading={index > 2 ? "lazy" : null}
          sku={sku}
          filename={image.filename}
          alt={image.title}
          hasIpvaPaid={hasIpvaPaid}
        />
      ))}
    </Carousel>
  );
};

export default CardImageCarousel;
