import { useEffect, useState } from "react";
import { useWebsocket } from "hooks/useWebsocket";

export const useVehicleWebsocket = () => {
  const socket = useWebsocket();
  const [websocketData, setWebsocketData] = useState({});

  useEffect(() => {
    socket?.on("resale/change-price", (data) => {
      setWebsocketData(data);
    });
  }, [socket]);

  return websocketData;
};
