import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    height: "30px",
  },
  icon: {
    alignItems: "center",
    display: "flex",
    paddingRight: theme.spacing(0.5),
  },
  description: {
    color: "#fff",
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightMedium,
  },
  offer: {
    borderRadius: 0,
    margin: "16px 0 24px",
    padding: theme.spacing(2, 0),
  },
  space: {
    height: 40,
    display: "block",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      alignItems: "center",
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0, 0.5),
      width: "100%",
    },
    description: {
      color: "#fff",
      fontSize: "10px",
    },
    space: {
      height: 36,
    },
  },
}));

const LAST_MINUTES_TO_SHOW = 30;

const leadingZero = (num) => num.toString().padStart(2, "0");

const isTimeExpired = (limitDate) => {
  const now = new Date().getTime();
  const countDownDate = Date.parse(limitDate);
  return countDownDate < now;
};

const countDown = (limitDate) => {
  const now = new Date().getTime();
  const countDownDate = Date.parse(limitDate);

  const timeLeft = countDownDate - now;

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;
  const MILLISECONDS_PER_MINUTE = 1000 * 60;
  const MILLISECONDS_PER_SECOND = 1000;

  let days = timeLeft / MILLISECONDS_PER_DAY;
  let hours = (timeLeft % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR;
  let minutes = (timeLeft % MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE;
  let seconds = (timeLeft % MILLISECONDS_PER_MINUTE) / MILLISECONDS_PER_SECOND;
  let timeLeftInSeconds = timeLeft / MILLISECONDS_PER_SECOND;

  days = leadingZero(Math.floor(days));
  hours = leadingZero(Math.floor(hours));
  minutes = leadingZero(Math.floor(minutes));
  seconds = leadingZero(Math.floor(seconds));
  timeLeftInSeconds = Math.floor(timeLeftInSeconds);

  return {
    timeLeftInSeconds: timeLeftInSeconds,
    timer: `${hours}:${minutes}:${seconds}`,
  };
};

const CountDown = ({ text, type }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${type === "offer" ? classes.offer : ""}`}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.icon}>
          <QueryBuilderIcon />
        </Grid>
        <Grid item>
          <Typography className={classes.description}>{text}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const VehicleCountDown = ({
  limitDate,
  finishMessage,
  type = "vehicle",
  onStartedCount = () => {},
}) => {
  const [timeLeft, setTimeLeft] = useState(() => countDown(limitDate));
  const [showCountDown, setShowCountDown] = useState(false);
  const [onStartNotified, setOnStartNotified] = useState(false);
  const lastSecondsToShow = LAST_MINUTES_TO_SHOW * 60;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(countDown(limitDate));
      setShowCountDown(timeLeft.timeLeftInSeconds < lastSecondsToShow);
    }, 1000);

    if (isTimeExpired(limitDate)) {
      clearTimeout(timer);
    }

    if (showCountDown && !onStartNotified) {
      setOnStartNotified(true);
      onStartedCount();
    }

    return () => clearTimeout(timer);
  });

  if (isTimeExpired(limitDate)) {
    return <CountDown text={finishMessage} type={type} />;
  }

  return showCountDown ? (
    <CountDown text={`Encerra em ${timeLeft.timer}`} type={type} />
  ) : null;
};

export default VehicleCountDown;
