import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Link from "next/link";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    borderRadius: "8px 8px 0px 0px",
    objectFit: "cover",
    height: "165px",
  },
  ipva: {
    position: "absolute",
    zIndex: 1,
    bottom: "15px",
    right: "15px",
    width: "64px",
  },
  "@media (max-width: 599px)": {
    image: {
      width: "148px",
      height: "112px",
      borderRadius: "8px 8px 8px 8px",
      float: "left",
    },
    ipva: {
      position: "absolute",
      zIndex: 1,
      bottom: "-107px",
      left: "5px",
      width: "46px",
    },
    containerImage: {
      position: "relative",
    },
  },
}));

const MobileImage = ({ sku, href, filename, alt, hasIpvaPaid, ...rest }) => {
  const classes = useStyles();
  const placeholder = "/img/placeholder.webp";

  const handleError = (e) => {
    const imageElement = e.target;
    imageElement.src = placeholder;
  };

  return (
    <Paper elevation={0}>
      <Link href={href} passHref>
        <a target="_blank" rel="noreferrer">
          <div className={classes.containerImage}>
            <img
              {...rest}
              className={classes.image}
              src={`${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}/sm/${filename}`}
              alt={alt}
              onError={handleError}
            />
            {hasIpvaPaid ? (
              <img
                className={classes.ipva}
                src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/leilao%2FhasIpvaPaid2024.png"
                alt="IPVA pago"
              />
            ) : null}
          </div>
        </a>
      </Link>
    </Paper>
  );
};

export default MobileImage;
