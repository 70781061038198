import { Grid, useMediaQuery } from "@material-ui/core";
import SingleCardImage from "./SingleCardImage";
import VehicleCountDown from "./VehicleCountDown";
import CardImageCarousel from "./CardImageCarousel";

const imagePick = [
  "frente-passageiro",
  "frente-motorista",
  "painel-completo",
  "traseira-motorista",
];

const sortFunc = (a, b) =>
  imagePick.indexOf(a.type) - imagePick.indexOf(b.type);

const CardImage = ({ sku, isIpvaPaid, imageList, href, adExpirationTime }) => {
  const mobile = useMediaQuery("(max-width: 599px)");

  const filteredImageList = imageList
    .filter((image) => imagePick.includes(image.type))
    .sort(sortFunc);

  if (mobile && filteredImageList.length) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <SingleCardImage
            href={href}
            sku={sku}
            filename={filteredImageList[0].filename}
            alt={filteredImageList[0].title}
            hasIpvaPaid={isIpvaPaid}
          />
        </Grid>
        <Grid item>
          <VehicleCountDown
            limitDate={adExpirationTime}
            finishMessage="Oferta encerrada"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <CardImageCarousel
      href={href}
      sku={sku}
      imageList={filteredImageList}
      hasIpvaPaid={isIpvaPaid}
    />
  );
};

export default CardImage;
