import { makeStyles } from "@material-ui/core";
import Flag from "./Flag";

const useStyles = makeStyles((theme) => ({
  flagContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    top: -7,
    right: 0,
    "@media (max-width: 599px)": {
      top: -10,
    },
  },
}));

const FlagContainer = ({ isExclusive, cautionInspectionStatus, principal }) => {
  const classes = useStyles();

  const isPartOfLoyaltyProgram = isExclusive;

  const isCautionInspectionApproved =
    cautionInspectionStatus === "Aprovado com apontamento" ||
    cautionInspectionStatus === "Aprovado";

  const isCautionInspectionDisapproved =
    cautionInspectionStatus === "Reprovado";

  const isPrivateOwnerVehicle = principal === "Veículos de Particular";

  return (
    <div className={classes.flagContainer}>
      <Flag
        active={isCautionInspectionApproved}
        src="/revenda/approved-inspection-tag.svg"
        alt="laudo cautelar aprovado"
      />
      <Flag
        active={isCautionInspectionDisapproved}
        src="/revenda/failed-inspection-tag.svg"
        alt="laudo cautelar reprovado"
      />
      <Flag
        active={isPartOfLoyaltyProgram}
        src="/revenda/fidelidade.svg"
        alt="exclusivo platinum Santander"
      />
      <Flag
        active={isPrivateOwnerVehicle}
        src="/revenda/particular-tag.svg"
        alt="veículos de particular"
      />
    </div>
  );
};

export default FlagContainer;
