import React, { useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useRouter } from "next/router";
import Link from "next/link";
import ModalMessage from "components/Modals/ModalMessage";
import VehicleCountDown from "./VehicleCountDown";
import { setCookie } from "../../cookie";
import Location from "./Location";
import CardImage from "./CardImage";
import FlagContainer from "./FlagContainer";
import CardBookmarkTag from "./CardBookmarkTag";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: "20px 20px 10px 20px",
    position: "relative",
  },
  title: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "5px",
    color: "#2E323C",
    maxWidth: "80%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  version: {
    textTransform: "lowercase",
    lineHeight: "28px",
    color: "#40474F",
    height: "55px",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  link: {
    textDecoration: "none",
    "&:visited": {
      color: "transparent",
    },
  },
  price: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    margin: "15px 0px",
    color: "#2E323C",
  },
  yearVehicle: {
    "& p": {
      color: "#40474F",
    },
  },
  card: {
    position: "relative",
  },
  "@media (max-width: 599px)": {
    particularTag: {
      top: "-10px",
    },
    inspectionTag: {
      top: "-10px",
    },
    ht: 0,
    title: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    link: {
      width: "100%",
    },
    version: {
      fontSize: "12px",
      lineHeight: "17px",
      height: "auto",
    },
    price: {
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: "16px",
      margin: "10px 0px 2px",
      lineHeight: "21px",
    },
    yearVehicle: {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#40474F",
    },
    card: {
      padding: "10px 8px",
      minHeight: "132px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
    },
    details: {
      padding: "4px 0 0 10px",
      width: "100%",
    },
  },
}));

const getVehicleLink = (sku, isAuthenticated, pathname, group) => {
  const exclusive = group !== "Revenda" ? "exclusivo/" : "";
  const nps = isAuthenticated ? "nps=vai" : "";
  const isCatalogoWebmotorsPage =
    pathname === "/catalogo-webmotors" ? "origem=webmotors" : "";

  return `/veiculo/${exclusive}${sku}?${nps}${isCatalogoWebmotorsPage}`;
};

const SANTANDER_PRINCIPAL_IDS = [3110, 54135, 53923, 19726, 38346];

const VehicleCard = ({ vehicle, websocketData, isUserAuthenticated }) => {
  const router = useRouter();
  const { query } = router;
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 599px)");

  useEffect(() => {
    if (query.origem === "webmotors") {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 999);

      setCookie("cockpitWebmotors", true, {
        path: "/",
        expires: expirationDate,
      });
    }
  }, [query.origem]);

  if (websocketData.sku === vehicle.sku) {
    vehicle.price = websocketData.nextOffer;
    vehicle.ad_expiration_time = websocketData.adExpirationTime;
    vehicle.isVehicleSold = websocketData.isVehicleSold;
  }

  const isIpvaPaid =
    vehicle.ad_status === "advertised" &&
    SANTANDER_PRINCIPAL_IDS.includes(vehicle.principal.id);

  const vehicleLink = getVehicleLink(
    vehicle.sku,
    isUserAuthenticated,
    router.pathname,
    vehicle.group.label
  );

  return (
    <Paper elevation={0} variant="outlined" className={classes.card}>
      <CardBookmarkTag sku={vehicle.sku} group={vehicle?.group.label} />
      {vehicle.isVehicleSold ? (
        <ModalMessage
          title="Veículo Vendido!"
          message={`O veículo ${vehicle.name} acabou de ser vendido. Clique no botão abaixo para atualizar a página.`}
          onClick={router.reload}
          onClose={router.reload}
        />
      ) : null}

      <CardImage
        href={vehicleLink}
        sku={vehicle.sku}
        isIpvaPaid={isIpvaPaid}
        imageList={vehicle.images}
        adExpirationTime={vehicle.ad_expiration_time}
      />

      <Link href={vehicleLink} passHref>
        <a className={classes.link} target="_blank" rel="noreferrer">
          <div className={classes.details}>
            <FlagContainer
              principal={vehicle?.principal?.label}
              isExclusive={vehicle?.isExclusive}
              cautionInspectionStatus={vehicle?.cautionInspection?.status}
            />
            <Typography variant="h5" className={classes.title} gutterBottom>
              {vehicle.brand.label} {vehicle.model.label}
            </Typography>
            <Typography
              className={classes.version}
              variant="body1"
              gutterBottom
            >
              {vehicle.version.label}
            </Typography>
            {mobile ? (
              <Typography variant="body2" className={classes.yearVehicle}>
                {vehicle.manufactureYear}/{vehicle.modelYear} |{" "}
                {vehicle.inspection.mileage.value} KM
              </Typography>
            ) : null}
            <Typography variant="body1" className={classes.price}>
              <strong>
                R${" "}
                {Math.floor(parseFloat(vehicle.price)).toLocaleString("pt-br")}
              </strong>
            </Typography>
            {!mobile ? (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.yearVehicle}
                >
                  <Grid item>
                    <Typography variant="body2">
                      {vehicle.manufactureYear}/{vehicle.modelYear}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" gutterBottom>
                      {vehicle.inspection.mileage.value} KM
                    </Typography>
                  </Grid>
                </Grid>
                <VehicleCountDown
                  limitDate={vehicle.ad_expiration_time}
                  finishMessage="Oferta encerrada"
                />
              </>
            ) : null}
            <Location city={vehicle.location.city} />
          </div>
        </a>
      </Link>
    </Paper>
  );
};
export default VehicleCard;
