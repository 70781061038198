import { useEffect, useState } from "react";
import io from "socket.io-client";

export const useWebsocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketIo = io(process.env.NEXT_PUBLIC_WEBSOCKET_WSS, {
      transports: ["websocket"],
      reconnection: true,
    });

    setSocket(socketIo);

    socketIo.on("connect", () => {});

    socketIo.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    return () => {
      socketIo.disconnect();
    };
  }, []);

  return socket;
};
