import React, { useState } from "react";
import {
  makeStyles,
  Modal as ModalCustom,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    borderRadius: "8px",
    left: "50%",
    maxWidth: "384px",
    padding: "30px 10px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    textAlign: "center",
    width: "100%",
  },
  separator: {
    backgroundColor: "#E9ECEE",
    borderRadius: "3px",
    height: "5px",
    margin: "0 auto 20px",
    width: "20px",
  },
  body: {
    margin: "0 10px",
  },
  title: {
    fontWeight: theme.typography.h3.fontWeight,
    margin: "0 auto 10px",
  },
  informative: {
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: "24px",
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    display: "block",
    margin: "0 auto",
    width: "45%",
  },
  [theme.breakpoints.up("md")]: {
    root: {
      padding: "40px 25px",
    },
    closeButton: {
      display: "block",
      margin: "0 auto",
      padding: "0 50px",
      width: "auto",
    },
  },
}));

const ModalMessage = ({ title, message, onClick, onClose = null }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);

    if (onClick) {
      onClick();
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalCustom
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h3" id="modal-title">
            {title}
          </Typography>
        </div>
        <div className={classes.separator} />
        <div className={classes.body}>
          <Typography className={classes.informative} variant="h5" gutterBottom>
            {message}
          </Typography>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            className={classes.closeButton}
          >
            Fechar
          </Button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default ModalMessage;
